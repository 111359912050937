/* You can add global styles to this file, and also import other style files */
@use 'sass:map';
@use '@angular/material' as mat;
@use './assets//styles/theme.scss' as mytheme;
@import '~bootstrap/dist/css/bootstrap.min.css';
@import './assets/styles/layout-style.scss';
@import './assets/styles/fonts.scss';
@import '../node_modules/@angular/material/theming';
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");

html, body { height: 100%; overflow-y: hidden;}
body { margin: 0; font-family: "Roboto", "Helvetica Neue", sans-serif;}
p, span, h1, h2, h3, h4, h5, button {font-family: "Roboto", sans-serif}

@include mat.core();
// Define a custom palette with your primary and secondary colors
$custom-primary: mat.define-palette(mat.$indigo-palette, 500);
$custom-accent: mat.define-palette(mat.$pink-palette, 900);

$custom-theme: mat.define-light-theme((
  color: (
    primary: $custom-primary,
    accent: $custom-accent,
  )
));

// Apply the custom theme
@include mat.all-component-themes($custom-theme);
@include mytheme.theme($custom-theme);
