@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');

    // Get the primary color palette from the color-config.
    $accent-palette: map.get($color-config, 'accent');

    .primary-theme {
        color: white!important;
        background: mat.get-color-from-palette($primary-palette)!important;
    }

    .selected-item-theme {
        background: mat.get-color-from-palette($primary-palette, 400)!important;
    }

    .accent-theme {
        color: white!important;
        background: mat.get-color-from-palette($accent-palette)!important;
    }

    .accent-color {
        color: mat.get-color-from-palette($accent-palette)!important;
    }

    .auth-background-theme {
        $color: mat.get-color-from-palette($accent-palette);
        background: linear-gradient(180deg,$color 36%,#fff 30%)!important;
    }
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }

    $typography-config: mat.get-typography-config($theme);

    @if $typography-config !=null {
        @include typography($theme);
    }
}