.disabled {
    opacity: 0.5 !important;
}

.mat-form-field-full {
    width: 100%;
}

//Dialog
.mat-mdc-dialog-title {
    font-size: 16px !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 0px !important;
}

.mat-dialog-close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.offer-dialog-component {
    .mat-mdc-dialog-content {
        max-height: fit-content !important;
    }
}

.image-cropper-dialog {
    width: 700px;
}

.video-player-dialog {
    width: 700px;
}

.alert-dialog {
    width: 350px;

    p {
        font-size: 14px;
        color: black;
    }
}

.confirmation-dialog {
    width: 400px;

    .mdc-dialog__title {
        margin-bottom: 0px !important;
    }
}

//Button
.button-theme {
    background: #85c51f !important;
    color: #fff !important;
}

.button-text-theme {
    color: #85c51f !important;
    font-weight: bold;
}

.flex-spacer {
    flex: 1 1 auto;
}

.app-outlined-dialog {
    position: relative !important;

    .close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
}

.hidden-scroll {
    &::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
    }

    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.app-button {

    .mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
    .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
        stroke: #ffffff !important;
    }
}

.mdc-text-field--filled {
    background: white !important;

    &:hover {
        background: white !important;
    }
}

//Form field
.app-auth-form-field {
    .mdc-text-field {
        padding-left: 0px !important;
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
        padding: 0px !important;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
        border-bottom-color: #e1e1e1 !important;
    }

    input::placeholder {
        color: #cccccc !important;
    }

    font-size: 20px;
}

.app-form-field {
    .mat-mdc-text-field-wrapper {
        height: 50px !important;
    }

    .mdc-text-field {
        padding-left: 0px !important;
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
        padding: 0px !important;
    }

    input::placeholder {
        color: #cccccc !important;
    }

    .mat-mdc-select-placeholder {
        color: #cccccc !important;
    }

    .mdc-floating-label {
        color: #818181 !important;
        font-size: 18px !important;
        font-family: Roboto !important;
        letter-spacing: normal;
    }
}

.app-form-field-text {
    .mdc-text-field {
        padding-left: 0px !important;
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
        padding: 0px !important;
    }

    input::placeholder {
        color: #cccccc !important;
    }

    .mdc-floating-label {
        color: #818181 !important;
        font-size: 18px !important;
        font-family: Roboto !important;
        letter-spacing: normal;
    }
}

.app-form-phone-field {
    input {
        padding-left: 68px;
    }
}

//Text Input bottom border color
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: #e1e1e1 !important;
}

//Table
.mat-mdc-header-cell {
    color: #abb0b0 !important;
    font-size: 16px;
    font-weight: 500;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
    border-bottom-color: #f4f5f5 !important;
}

.mat-mdc-table .mdc-data-table__row {
    &:hover {
        background: #eeeeee !important;
    }
}

//Custom Menu
.phone-code-menu-list {
    height: 200px !important;
}