@charset "UTF-8";
/* Roboto regular */

@font-face {
    font-family: "Roboto";
    src: url("/assets/fonts/Roboto-Regular.eot");
    src: url("/assets/fonts/Roboto-Regular.eot?#iefix")
        format("embedded-opentype"),
      url("/assets/fonts/Roboto-Regular.woff2") format("woff2"),
      url("/assets/fonts/Roboto-Regular.woff") format("woff"),
      url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  
  /* Roboto Light */
  
  @font-face {
    font-family: "Roboto-light";
    src: url("/assets/fonts/Roboto-Light.eot");
    src: url("/assets/fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"),
      url("/assets/fonts/Roboto-Light.woff2") format("woff2"),
      url("/assets/fonts/Roboto-Light.woff") format("woff"),
      url("/assets/fonts/Roboto-Light.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  
  /* Roboto italic */
  
  @font-face {
    font-family: "Roboto-italic";
    src: url("/assets/fonts/Roboto-Italic.eot");
    src: url("/assets/fonts/Roboto-Italic.eot?#iefix") format("embedded-opentype"),
      url("/assets/fonts/Roboto-Italic.woff2") format("woff2"),
      url("/assets/fonts/Roboto-Italic.woff") format("woff"),
      url("/assets/fonts/Roboto-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
  }
  
  /* Roboto bold */
  
  @font-face {
    font-family: "Roboto-bold";
    src: url("/assets/fonts/Roboto-Bold.eot");
    src: url("/assets/fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"),
      url("/assets/fonts/Roboto-Bold.woff2") format("woff2"),
      url("/assets/fonts/Roboto-Bold.woff") format("woff"),
      url("/assets/fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }